* {
    box-sizing: border-box;
  }
  
  .projects html,
  .projects body {
    height: 100%;
    padding: 0;
    margin: 0;
    font-family: 'avenir next', avenir, -apple-system, BlinkMacSystemFont,
      'helvetica neue', helvetica, ubuntu, roboto, noto, 'segoe ui', arial,
      sans-serif;
    color: #374047;
  }
  
  .projects #root {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .projects body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .projects .main {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    width: 100%;
  }
  
  .projects .info {
    position: relative;
    z-index: 1;
    color: white;
    text-align: center;
    margin-bottom: 3rem;
    font-size: 14px;
    a {
      color: inherit;
    }
  
    .projects .notice {
      margin-top: 1rem;
      font-weight: 100;
    }
  
    @media screen and (min-width: 980px) {
      font-size: 18px;
      .projects .notice {
        display: none;
      }
    }
  }
  
  .projects .hero {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
  }
  
  .projects .hero-body {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    padding: 3rem 1.5rem;
  
    @media screen and (min-width: 980px) {
      padding: 8rem 1.5rem;
    }
  }
  
  .projects .container {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
  }
  
  .projects .row {
    display: flex;
    flex-wrap: wrap;
  }
  
  .projects .column {
    display: flex;
    flex: 1 1 auto;
    padding: 10px;
    width: 100%;
    @media screen and (min-width: 980px) {
      width: 50%;
    }
  }
  
  .projects .card {
    display: flex;
    flex-direction: column;
    padding: 40px;
    background-color: #B0B0B0;
    box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
    transition: box-shadow 0.5s;
    will-change: transform;
  }
  
  .projects .card-title {
    font-size: 22px;
    font-weight: 600;
  }
  
  .projects .card-body {
    margin-top: 27px;
    margin-bottom: 27px;
    line-height: 1.5;
    font-size: 16px;
    @media screen and (min-width: 576px) {
      font-size: 18px;
    }
  }

  .projects .card-body p{
    white-space: pre-wrap;
  }
  
  .projects .image-container {
    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    @media screen and (min-width: 800px) {
      padding-bottom: 0px;
    }
  
    img {
      width: 100%;
    }
  }
  
  .projects .image-inner-container {
    width: 100%;
    margin: 0;
  }
  
  .projects .ratio {
    position: relative;
    width: 100%;
    padding-top: 56%;
    overflow: hidden;
  }
  
  .projects .ratio-inner {
    position: absolute;
    top: 0 auto;
    right: 0;
    bottom: 0;
    left: 0;
  }
  


.card {
	border: 3px solid #000000;
  	background-color: #000000;
}

.card-3d{
	box-shadow: -10px 10px 0 0 #000000;
	transition: 0.25s ease;

	&:hover {
		transform: translate(-10px, 10px);
		border-color: #000000;
		box-shadow: 0 0 0 0 #000000;
	}
}

.no-style {
	all:revert;
}

.card-container {
	flex: 300px;
	margin: 15px 2px;
  
	.card {
		font-weight: bold;
		position: relative;
		background: #696969;
		border: 3px solid #000000;
		width: 100%;
		&:hover {
			border: 3px dashed #505050;
		}
 
		a {
			padding: 30px;
			width: 100%;
			height: 400px;
			background: grey;
			text-decoration: none;
			color: black;
			display: block;
			transition: 0.25s ease;
			
			&:hover {
				background-color: 	#DCDCDC;
				transform: translate(10px, 10px);
				border: 3px dashed #505050;
				
				.card--display {
					display: none;
				}
				
				.card--hover {
					display: block;
				}
			}
			.card--display {
				i {
					font-size: 50px;
					margin-top: 200px;
				}

				h3 {
					color: #000000;
				}

				h5 {
					color: #000000;
					margin: 20px 0 0;
				}
				
				h6 {
					color: #000000
				}
			}
			.card--hover {
				display: none;
				h1{
					color: #505050;
				}
				
				h2 {
					margin: 20px 0;
					color: #505050;
				}
				
				h3 {
					margin: 20px 0;
					color: #000033;
				}

				p {
					font-weight: normal;
					line-height: 1.5;
					
					&.link {
						margin: 20px 0 0;
						font-weight: bold;
						color: #000033;
					}
				}
			}
			.card--border {
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				border: 2px dashed black;
				z-index: -3;
			}
		}
		
		&.card--dark {
			a {
				color: 	#DCDCDC;
				background-color: black;
				border-color: black;
				
				.card--hover {
					.link {
						color: #505050;
					}
					

				}
			}
    	}
  }
}
